.mainPartnerLogo {
  width: 48px !important;
}

.mainPartnerIcon {
  height: 90px;
}

.partnerLogo {
  width: 48px !important;
}

.businessPartnersList {
  max-height: calc(
    100vh /* Screen height */ - 56px /* Header */ - 35px /* connected sentence */ - 80px /* user */ -
      39px /* searchbar */ - 56px /* disconnect button */
  );
  overflow-y: scroll;
}

.businessPartnerFilterIcon {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid white !important;
}

.businessPartnerFilterInput {
  border: 0 !important;
  border-radius: 0 !important;
}
