.rc-time-picker-input {
  @extend .form-control;
}

.rc-time-picker.is-invalid > .rc-time-picker-input {
  @extend .is-invalid;
}

.rc-time-picker-input[disabled] {
  color: $midnight-blue;
}

.rc-time-picker-panel-input {
  @extend .form-control;
  @extend .border-0;
}
