.menu {
  width: 400px;
}

.avatar {
  max-height: 40px !important;
}

.bottomLogo {
  bottom: 5px;
  right: 5px;
}
