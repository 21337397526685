.rootContainer {
  height: 100vh;
}

.pageContainer {
  padding-top: 56px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
