@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

//
// Color system
//

// Bootstrap colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$pink: #e83e8c;
$orange: #fd7e14;
$yellow: #ffc107;
$teal: #20c997;
$cyan: #17a2b8;
$dark: #343a40;

// Design system colors
$midnight-blue: #000031;
$jasmin: #f7d48a;
$light-jasmin: #f8e5b6;
$mango: #f5b400;
$purple: #9ca3ff;
$grey-blue: #708399;
$light-grey-blue: #eceff6;
$dark-grey: #a9b3c5;
$medium-grey: #c7cdda;
$red: #df6262;
$green: #59c871;
$bronze: #b96c47;
$silver: #c6c6c6;
$gold: #e6ba5e;
$platinum: #a07584;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'pink': $pink,
  'orange': $orange,
  'yellow': $yellow,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'dark': $dark,
  'midnight-blue': $midnight-blue,
  'jasmin': $jasmin,
  'light-jasmin': $light-jasmin,
  'mango': $mango,
  'purple': $purple,
  'grey-blue': $grey-blue,
  'light-grey-blue': $light-grey-blue,
  'dark-grey': $dark-grey,
  'medium-grey': $medium-grey,
  'red': $red,
  'green': $green,
  'bronze': $bronze,
  'silver': $silver,
  'gold': $gold,
  'platinum': $platinum,
);

// Theme colors
$primary: $midnight-blue;
$light-primary: $grey-blue;
$neutral: $medium-grey;
$light-neutral: $light-grey-blue;
$dark-neutral: $dark-grey;
$background: $white;
$accent: $jasmin;
$light-accent: $light-jasmin;
$info: $mango;
$action: $purple;
$success: $green;
$error: $red;
$bronze: $bronze;
$silver: $silver;
$gold: $gold;
$platinum: $platinum;

$theme-colors: (
  'primary': $midnight-blue,
  'secondary': $accent,
  'light-primary': $grey-blue,
  'neutral': $medium-grey,
  'light-neutral': $light-grey-blue,
  'dark-neutral': $dark-grey,
  'background': $background,
  'accent': $jasmin,
  'light-accent': $light-jasmin,
  'info': $mango,
  'action': $purple,
  'success': $green,
  'error': $red,
  'bronze': $bronze,
  'silver': $silver,
  'gold': $gold,
  'platinum': $platinum,
);

:export {
  blue: $blue;
  midnightBlue: $midnight-blue;
  indigo: $indigo;
  purple: $purple;
  pink: $pink;
  red: $red;
  orange: $orange;
  yellow: $yellow;
  green: $green;
  teal: $teal;
  cyan: $cyan;
  darkenBlue: mix($blue, $dark, 20%);
  darkenMidnightBlue: mix($midnight-blue, $dark, 20%);
  darkenIndigo: mix($indigo, $dark, 20%);
  darkenPurple: mix($purple, $dark, 20%);
  darkenPink: mix($pink, $dark, 20%);
  darkenRed: mix($red, $dark, 20%);
  darkenOrange: mix($orange, $dark, 20%);
  darkenYellow: mix($yellow, $dark, 20%);
  darkenGreen: mix($green, $dark, 20%);
  darkenTeal: mix($teal, $dark, 20%);
  darkenCyan: mix($cyan, $dark, 20%);
}

// // Set a specific jump point for requesting color jumps
// $theme-color-interval:      8%;

// // The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
// $yiq-contrasted-threshold:  150;

// // Customize the light and dark text colors for use in our YIQ color contrast function.
// $yiq-text-dark:             $gray-900;
// $yiq-text-light:            $white;

// // Options
// //
// // Quickly modify global styling by enabling or disabling optional features.

// $enable-caret:              true;
// $enable-rounded:            true;
// $enable-shadows:            false;
// $enable-gradients:          false;
// $enable-transitions:        true;
// $enable-hover-media-query:  false; // Deprecated, no longer affects any compiled CSS
// $enable-grid-classes:       true;
// $enable-print-styles:       true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: 0.5rem,
    2: 1rem,
    3: 1.5rem,
    4: 2rem,
    5: 2.5rem,
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $background;
$body-color: $primary;

// Links
//
// Style anchor elements.

$link-color: $action;
$link-decoration: none;
$link-hover-color: $action;
$link-hover-decoration: underline;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-sm: 16px;
$line-height: 24px;
$line-height-lg: 44px;

$border-width: 1px;
$border-color: $neutral;

$border-radius-sm: 4px;
$border-radius: 15px;
$border-radius-lg: 30px;

$box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
// $box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
// $box-shadow-lg:               0 1rem 3rem rgba($black, .175);

$component-active-color: $white;
$component-active-bg: $accent;

$caret-width: 0.3em;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: GTWalsheim, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
  monospace;
$font-family-base: $font-family-sans-serif;

$font-size-xs: ($font-size-base * 0.7);
$font-size-sm: ($font-size-base * 0.875);
$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 32px;
$h2-font-size: 24px;
$h3-font-size: 30px;
// $h4-font-size:                $font-size-base * 1.5;
// $h5-font-size:                $font-size-base * 1.25;
// $h6-font-size:                $font-size-base;

$headings-margin-bottom: 1rem;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: $line-height;
$headings-color: inherit;

// $display1-size:               6rem;
// $display2-size:               5.5rem;
// $display3-size:               4.5rem;
// $display4-size:               3.5rem;

// $display1-weight:             300;
// $display2-weight:             300;
// $display3-weight:             300;
// $display4-weight:             300;
// $display-line-height:         $headings-line-height;

// $lead-font-size:              ($font-size-base * 1.25);
// $lead-font-weight:            300;

$small-font-size: 12px;

$text-muted: $primary;

// $blockquote-small-color:      $gray-600;
// $blockquote-font-size:        ($font-size-base * 1.25);

// $hr-border-color:             rgba($black, .1);
// $hr-border-width:             $border-width;

// $mark-padding:                .2em;

// $dt-font-weight:              $font-weight-bold;

// $kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
// $nested-kbd-font-weight:      $font-weight-bold;

// $list-inline-padding:         .5rem;

// $mark-bg:                     #fcf8e3;

// $hr-margin-y:                 $spacer;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 1rem;
// $table-cell-padding-sm:       .3rem;

$table-bg: transparent;
// $table-accent-bg:             rgba($black, .05);
// $table-hover-bg:              rgba($black, .075);
// $table-active-bg:             $table-hover-bg;

$table-border-width: 0;
$table-border-color: transparent;

// $table-head-bg:               $gray-200;
// $table-head-color:            $gray-700;

// $table-dark-bg:               $gray-900;
// $table-dark-accent-bg:        rgba($white, .05);
// $table-dark-hover-bg:         rgba($white, .075);
// $table-dark-border-color:     lighten($gray-900, 7.5%);
// $table-dark-color:            $body-bg;

// $table-striped-order:         odd;

// $table-caption-color:         $text-muted;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: $accent;
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.5rem;
$input-btn-line-height: $line-height;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;
$btn-line-height-sm: $line-height-sm;

$btn-padding-y: 0.5rem;
$btn-padding-x: 2rem;
$btn-line-height: $line-height;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;
$btn-line-height-lg: $line-height-sm;

$btn-border-width: $border-width;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow: none;
$btn-focus-width: none;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 0.8;
$btn-active-box-shadow: none;

$btn-link-disabled-color: $light-primary;

// $btn-block-spacing-y:         .5rem;

$btn-border-radius-sm: $border-radius-lg;
$btn-border-radius: $border-radius-lg;
$btn-border-radius-lg: $border-radius-lg;

$btn-transition: none;

// Forms

$label-margin-bottom: 0.25rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: $light-neutral;

$input-color: $body-color;
$input-border-color: $neutral;
$input-border-width: $input-btn-border-width;
$input-box-shadow: none;

$input-border-radius-sm: $border-radius-lg;
$input-border-radius: $border-radius-lg;
$input-border-radius-lg: $border-radius-lg;

$input-focus-bg: none;
$input-focus-border-color: $accent;
$input-focus-color: none;
$input-focus-width: none;
$input-focus-box-shadow: none;

$input-placeholder-color: $neutral;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: 40px;
$input-height: 42px;

$input-height-inner-sm: $input-height-inner;
$input-height-sm: $input-height;

$input-height-inner-lg: $input-height-inner;
$input-height-lg: $input-height;

$input-transition: border-color 0.15s ease-in-out;

$form-text-margin-top: 0.25rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;

$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;

$form-group-margin-bottom: 1rem;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;

// $custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// $custom-control-gutter:                 1.5rem;
// $custom-control-spacer-x:               1rem;

// $custom-control-indicator-size:         1rem;
// $custom-control-indicator-bg:           $gray-300;
// $custom-control-indicator-bg-size:      50% 50%;
// $custom-control-indicator-box-shadow:   inset 0 .25rem .25rem rgba($black, .1);

// $custom-control-indicator-disabled-bg:          $gray-200;
// $custom-control-label-disabled-color:           $gray-600;

// $custom-control-indicator-checked-color:        $component-active-color;
// $custom-control-indicator-checked-bg:           $component-active-bg;
// $custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5);
// $custom-control-indicator-checked-box-shadow:   none;

// $custom-control-indicator-focus-box-shadow:     0 0 0 1px $body-bg, $input-btn-focus-box-shadow;

// $custom-control-indicator-active-color:         $component-active-color;
// $custom-control-indicator-active-bg:            lighten($component-active-bg, 35%);
// $custom-control-indicator-active-box-shadow:    none;

// $custom-checkbox-indicator-border-radius:       $border-radius;
// $custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");

// $custom-checkbox-indicator-indeterminate-bg:          $component-active-bg;
// $custom-checkbox-indicator-indeterminate-color:       $custom-control-indicator-checked-color;
// $custom-checkbox-indicator-icon-indeterminate:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");
// $custom-checkbox-indicator-indeterminate-box-shadow:  none;

// $custom-radio-indicator-border-radius:          50%;
// $custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23");

// $custom-select-padding-y:           .375rem;
// $custom-select-padding-x:           .75rem;
// $custom-select-height:              $input-height;
// $custom-select-indicator-padding:   1rem; // Extra padding to account for the presence of the background-image based indicator
// $custom-select-line-height:         $input-btn-line-height;
// $custom-select-color:               $input-color;
// $custom-select-disabled-color:      $gray-600;
// $custom-select-bg:                  $input-bg;
// $custom-select-disabled-bg:         $gray-200;
// $custom-select-bg-size:             8px 10px; // In pixels because image dimensions
// $custom-select-indicator-color:     $gray-800;
// $custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");
// $custom-select-border-width:        $input-btn-border-width;
// $custom-select-border-color:        $input-border-color;
// $custom-select-border-radius:       $border-radius;
// $custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075);

// $custom-select-focus-border-color:  $input-focus-border-color;
// $custom-select-focus-width:         $input-btn-focus-width;
// $custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width rgba($custom-select-focus-border-color, .5);

// $custom-select-font-size-sm:        75%;
// $custom-select-height-sm:           $input-height-sm;

// $custom-select-font-size-lg:        125%;
// $custom-select-height-lg:           $input-height-lg;

// $custom-range-track-width:          100%;
// $custom-range-track-height:         .5rem;
// $custom-range-track-cursor:         pointer;
// $custom-range-track-bg:             $gray-300;
// $custom-range-track-border-radius:  1rem;
// $custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1);

// $custom-range-thumb-width:                   1rem;
// $custom-range-thumb-height:                  $custom-range-thumb-width;
// $custom-range-thumb-bg:                      $component-active-bg;
// $custom-range-thumb-border:                  0;
// $custom-range-thumb-border-radius:           1rem;
// $custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
// $custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
// $custom-range-thumb-focus-box-shadow-width:  $input-btn-focus-width; // For focus box shadow issue in IE/Edge
// $custom-range-thumb-active-bg:               lighten($component-active-bg, 35%);

// $custom-file-height:                $input-height;
// $custom-file-height-inner:          $input-height-inner;
// $custom-file-focus-border-color:    $input-focus-border-color;
// $custom-file-focus-box-shadow:      $input-btn-focus-box-shadow;
// $custom-file-disabled-bg:           $input-disabled-bg;

// $custom-file-padding-y:             $input-btn-padding-y;
// $custom-file-padding-x:             $input-btn-padding-x;
// $custom-file-line-height:           $input-btn-line-height;
// $custom-file-color:                 $input-color;
// $custom-file-bg:                    $input-bg;
// $custom-file-border-width:          $input-btn-border-width;
// $custom-file-border-color:          $input-border-color;
// $custom-file-border-radius:         $input-border-radius;
// $custom-file-box-shadow:            $input-box-shadow;
// $custom-file-button-color:          $custom-file-color;
// $custom-file-button-bg:             $input-group-addon-bg;
// $custom-file-text: (
//   en: "Browse"
// );

// // Form validation
// $form-feedback-margin-top:          $form-text-margin-top;
// $form-feedback-font-size:           $small-font-size;
// $form-feedback-valid-color:         theme-color("success");
// $form-feedback-invalid-color:       theme-color("danger");

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: $background;
$dropdown-border-color: transparent;
$dropdown-border-radius: $border-radius-lg;
$dropdown-border-width: 0;
// $dropdown-divider-bg:               $gray-200;
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: $background;

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: $background;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1rem;

// $dropdown-header-color:             $gray-600;

// // Z-index master list
// //
// // Warning: Avoid customizing these values. They're used for a bird's eye view
// // of components dependent on the z-axis and are designed to all work together.

// $zindex-dropdown:                   1000;
// $zindex-sticky:                     1020;
// $zindex-fixed:                      1030;
// $zindex-modal-backdrop:             1040;
// $zindex-modal:                      1050;
// $zindex-popover:                    1060;
// $zindex-tooltip:                    1070;

// Navs

// $nav-link-padding-y:                .5rem;
// $nav-link-padding-x:                1rem;
$nav-link-disabled-color: $neutral;

// $nav-tabs-border-color:             $gray-300;
// $nav-tabs-border-width:             $border-width;
// $nav-tabs-border-radius:            $border-radius;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color;
// $nav-tabs-link-active-color:        $gray-700;
// $nav-tabs-link-active-bg:           $body-bg;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

// $nav-pills-border-radius:           $border-radius;
// $nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// $nav-divider-color:                 $gray-200;
// $nav-divider-margin-y:              ($spacer / 2);

// Navbar

$navbar-padding-y: ($spacer / 2);
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   14px;
$navbar-brand-height: 56px;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  '#',
  '%23'
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  '#',
  '%23'
);
$navbar-light-toggler-border-color: rgba($black, 0.1);

// Pagination

$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: inherit;

$pagination-color: $primary;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-border-color: transparent;

$pagination-focus-box-shadow: none;
$pagination-focus-outline: 0;

$pagination-hover-color: $primary;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;

$pagination-active-color: none;
$pagination-active-bg: $accent;
$pagination-active-border-color: none;

$pagination-disabled-color: $neutral;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;

// // Jumbotron

// $jumbotron-padding:                 2rem;
// $jumbotron-bg:                      $gray-200;

// Cards

// $card-spacer-y:                     .75rem;
$card-spacer-x: 1.5rem;
$card-border-width: 0;
$card-border-radius: $border-radius-lg;
$card-border-color: $neutral;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
// $card-cap-bg:                       rgba($black, .03);
$card-bg: $background;

// $card-img-overlay-padding:          1.25rem;

// $card-group-margin:                 ($grid-gutter-width / 2);
// $card-deck-margin:                  $card-group-margin;

// $card-columns-count:                3;
// $card-columns-gap:                  1.25rem;
// $card-columns-margin:               $card-spacer-y;

// // Tooltips

// $tooltip-font-size:                 $font-size-sm;
// $tooltip-max-width:                 200px;
// $tooltip-color:                     $white;
// $tooltip-bg:                        $black;
// $tooltip-border-radius:             $border-radius;
// $tooltip-opacity:                   .9;
// $tooltip-padding-y:                 .25rem;
// $tooltip-padding-x:                 .5rem;
// $tooltip-margin:                    0;

// $tooltip-arrow-width:               .8rem;
// $tooltip-arrow-height:              .4rem;
// $tooltip-arrow-color:               $tooltip-bg;

// // Popovers

// $popover-font-size:                 $font-size-sm;
// $popover-bg:                        $white;
// $popover-max-width:                 276px;
// $popover-border-width:              $border-width;
// $popover-border-color:              rgba($black, .2);
// $popover-border-radius:             $border-radius-lg;
// $popover-box-shadow:                0 .25rem .5rem rgba($black, .2);

// $popover-header-bg:                 darken($popover-bg, 3%);
// $popover-header-color:              $headings-color;
// $popover-header-padding-y:          .5rem;
// $popover-header-padding-x:          .75rem;

// $popover-body-color:                $body-color;
// $popover-body-padding-y:            $popover-header-padding-y;
// $popover-body-padding-x:            $popover-header-padding-x;

// $popover-arrow-width:               1rem;
// $popover-arrow-height:              .5rem;
// $popover-arrow-color:               $popover-bg;

// $popover-arrow-outer-color:         fade-in($popover-border-color, .05);

// // Badges

// $badge-font-size:                   75%;
// $badge-font-weight:                 $font-weight-bold;
// $badge-padding-y:                   .25em;
// $badge-padding-x:                   .4em;
// $badge-border-radius:               $border-radius;

// $badge-pill-padding-x:              .6em;
// // Use a higher than normal value to ensure completely rounded edges when
// // customizing padding or font-size on labels.
// $badge-pill-border-radius:          10rem;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem;

// $modal-dialog-margin:               .5rem;
// $modal-dialog-margin-y-sm-up:       1.75rem;

$modal-title-line-height: $line-height-lg;

$modal-content-bg: $background;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-lg;
// $modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
// $modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 0;

// $modal-lg:                          800px;
// $modal-md:                          500px;
// $modal-sm:                          300px;

// $modal-transition:                  transform .3s ease-out;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 1rem;
$alert-padding-x: 1rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

// $alert-bg-level:                    -10;
// $alert-border-level:                -9;
// $alert-color-level:                 6;

// // Progress bars

// $progress-height:                   1rem;
// $progress-font-size:                ($font-size-base * .75);
// $progress-bg:                       $gray-200;
// $progress-border-radius:            $border-radius;
// $progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1);
// $progress-bar-color:                $white;
// $progress-bar-bg:                   theme-color("primary");
// $progress-bar-animation-timing:     1s linear infinite;
// $progress-bar-transition:           width .6s ease;

// List group

$list-group-bg: $background;
$list-group-border-color: $neutral;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius-lg;

$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

$list-group-hover-bg: $light-neutral;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $neutral;
$list-group-disabled-bg: $list-group-bg;

// $list-group-action-color:           $gray-700;
// $list-group-action-hover-color:     $list-group-action-color;

// $list-group-action-active-color:    $body-color;
// $list-group-action-active-bg:       $gray-200;

// // Image thumbnails

// $thumbnail-padding:                 .25rem;
// $thumbnail-bg:                      $body-bg;
// $thumbnail-border-width:            $border-width;
// $thumbnail-border-color:            $gray-300;
// $thumbnail-border-radius:           $border-radius;
// $thumbnail-box-shadow:              0 1px 2px rgba($black, .075);

// // Figures

// $figure-caption-font-size:          90%;
// $figure-caption-color:              $gray-600;

// // Breadcrumbs

// $breadcrumb-padding-y:              .75rem;
// $breadcrumb-padding-x:              1rem;
// $breadcrumb-item-padding:           .5rem;

// $breadcrumb-margin-bottom:          1rem;

// $breadcrumb-bg:                     $gray-200;
// $breadcrumb-divider-color:          $gray-600;
// $breadcrumb-active-color:           $gray-600;
// $breadcrumb-divider:                quote("/");

// $breadcrumb-border-radius:          $border-radius;

// // Carousel

// $carousel-control-color:            $white;
// $carousel-control-width:            15%;
// $carousel-control-opacity:          .5;

// $carousel-indicator-width:          30px;
// $carousel-indicator-height:         3px;
// $carousel-indicator-spacer:         3px;
// $carousel-indicator-active-bg:      $white;

// $carousel-caption-width:            70%;
// $carousel-caption-color:            $white;

// $carousel-control-icon-width:       20px;

// $carousel-control-prev-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
// $carousel-control-next-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");

// $carousel-transition:               transform .6s ease; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Close

$close-font-size: 2.5rem;
$close-font-weight: $font-weight-thin;
$close-color: $primary;
$close-text-shadow: none;

// // Code

// $code-font-size:                    87.5%;
// $code-color:                        $pink;

// $kbd-padding-y:                     .2rem;
// $kbd-padding-x:                     .4rem;
// $kbd-font-size:                     $code-font-size;
// $kbd-color:                         $white;
// $kbd-bg:                            $gray-900;

// $pre-color:                         $gray-900;
// $pre-scrollable-max-height:         340px;

// // Printing
// $print-page-size:                   a3;
// $print-body-min-width:              map-get($grid-breakpoints, "lg");

@import '~bootstrap/scss/bootstrap';

$hamburger-active-layer-color: $accent;
$hamburger-layer-color: $accent;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-height: 4px;
$hamburger-layer-width: 24px;
$hamburger-layer-spacing: 4px;

.rc-time-picker-input {
  @extend .form-control;
}

.rc-time-picker.is-invalid > .rc-time-picker-input {
  @extend .is-invalid;
}

.rc-time-picker-input[disabled] {
  color: $midnight-blue;
}

.rc-time-picker-panel-input {
  @extend .form-control;
  @extend .border-0;
}

.SingleDatePicker_picker {
  z-index: $zindex-popover;
}

.SingleDatePicker,
.SingleDatePickerInput,
.DateInput,
.DateInput_input {
  width: 100%;
  height: $input-height;
}

.SingleDatePickerInput {
  border: 0;
  background: transparent;
}

.DateInput {
  background-color: transparent;
}

.DateInput_input {
  @extend .form-control;
  border-radius: $input-border-radius;
  border: $input-border-width solid $input-border-color;
  font-weight: $font-weight-normal;
}

.is-invalid {
  .DateInput_input {
    @extend .is-invalid;
  }
}

.DateInput_input__disabled {
  font-style: normal;
}

.CalendarDay__selected {
  background: $primary !important;
  border-color: $primary;
  color: $white !important;
}

.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $primary;
  border-color: $accent;
}

.CalendarDay__default:hover {
  background: $primary;
  border-color: $primary;
  color: $white !important;
}

.CalendarDay__highlighted_calendar {
  background: $accent;
}

.CalendarDay {
  color: $primary;
}

.CalendarMonth_caption {
  color: $primary;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: $light;
  border-color: $light;
  color: $primary !important;
}

.container {
  height: 100vh;
  width: 280px;
  position: fixed;
  z-index: $zindex-fixed - 1;
}

.menuItem:hover {
  background-color: $light-primary;
}

.menuItem > span {
  display: inline-block;
  width: 32px;
  text-align: center;
  justify-content: center;
  padding-right: 0.5rem;
}
