.container {
  line-height: 5rem;
  height: 5rem;
}

.mark {
  font-size: 3rem;
}

.markAppend {
  font-size: 2.3rem;
}
