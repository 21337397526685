.rootContainer {
  height: 100vh;
}

.pageContainer {
  padding-top: 56px;
}

.withMenuVisible {
  margin-left: 280px;
}
