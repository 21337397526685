.SingleDatePicker_picker {
  z-index: $zindex-popover;
}

.SingleDatePicker,
.SingleDatePickerInput,
.DateInput,
.DateInput_input {
  width: 100%;
  height: $input-height;
}

.SingleDatePickerInput {
  border: 0;
  background: transparent;
}

.DateInput {
  background-color: transparent;
}

.DateInput_input {
  @extend .form-control;
  border-radius: $input-border-radius;
  border: $input-border-width solid $input-border-color;
  font-weight: $font-weight-normal;
}

.is-invalid {
  .DateInput_input {
    @extend .is-invalid;
  }
}

.DateInput_input__disabled {
  font-style: normal;
}

.CalendarDay__selected {
  background: $primary !important;
  border-color: $primary;
  color: $white !important;
}

.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $primary;
  border-color: $accent;
}

.CalendarDay__default:hover {
  background: $primary;
  border-color: $primary;
  color: $white !important;
}

.CalendarDay__highlighted_calendar {
  background: $accent;
}

.CalendarDay {
  color: $primary;
}

.CalendarMonth_caption {
  color: $primary;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: $light;
  border-color: $light;
  color: $primary !important;
}
